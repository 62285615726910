@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

/* fieldset legend p {
  font-size: 12px !important;
  line-height: '1.2em';
  text-overflow: unset; */
/* } */

/* .MuiInputLabel-outlined {
  width: 100%;
  padding-right: 2em;
} */

:root {
  --altcha-border-width: 1px;
  --altcha-border-radius: 3px;
  --altcha-color-base: #ffffff;
  --altcha-color-border: #a0a0a0;
  --altcha-color-text: currentColor;
  --altcha-color-border-focus: currentColor;
  --altcha-color-error-text: #f23939;
  --altcha-color-footer-bg: #f4f4f4;
  --altcha-max-width: 260px;
}

@media print {
  .h-screen {
    height: inherit !important;
  }

  .page-break {
    break-before: page;
    overflow: hidden;
  }
}
